/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,600,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700&display=swap'); */
/* @import url('https//db.onlinewebfonts.com/c/b05256c5c235ece15d5069d66c436c14?family=Berthold+Imago');
@font-face {
  font-family: 'Berthold Imago';
  src: url('//db.onlinewebfonts.com/t/1c68c2da68e4707fa6ec16385beb51cc.eot');
  src: url('//db.onlinewebfonts.com/t/1c68c2da68e4707fa6ec16385beb51cc.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/1c68c2da68e4707fa6ec16385beb51cc.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/1c68c2da68e4707fa6ec16385beb51cc.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/1c68c2da68e4707fa6ec16385beb51cc.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/1c68c2da68e4707fa6ec16385beb51cc.svg#Berthold Imago')
      format('svg');
} */
@font-face {
  font-family: 'RocheSans-Light';
  src: local('RocheSans-Light'),
    url(./fonts/RocheSans-Light.woff) format('woff');
}
@font-face {
  font-family: 'RocheSans';
  src: local('RocheSans'), url(./fonts/RocheSans-Regular.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'RocheSerif';
  src: local('RocheSerif'), url(./fonts/RocheSerif-Regular.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none; /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

input,
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

:root {
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #000000;
}

body {
  margin: 0;
  font-family: 'RocheSans-Light', 'RocheSerif', 'Open Sans', 'Berthold Imago',
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-stretch: condensed;
}

/* body {
  margin: 0;
  font-family: 'Berthold Imago', 'Open Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-stretch: condensed;
} */

button:disabled {
  opacity: 0.4;
}

button {
  cursor: pointer !important;
  box-shadow: 0rem 0.75rem 1rem rgba(194, 186, 181, 0.16),
    0rem 0.25rem 0.5rem rgba(84, 79, 79, 0.16);
}

button:hover {
  box-shadow: 0rem 0.75rem 1.563rem rgba(194, 186, 181, 0.16),
    0rem 0.25rem 1rem rgba(84, 79, 79, 0.16);
}

.grecaptcha-badge {
  z-index: 4;
}

#sending_circle {
  stroke-width: 0.625rem;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  animation: drawcircle 1s 0.5s forwards;
  transform: rotate(-90deg);
  transform-origin: 50%;
}

@keyframes drawcircle {
  to {
    stroke-dashoffset: 0;
  }
}
